const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import('../view/home.vue')
    },
    {
        name: 'company.introduction',
        path: '/companyIntroduction',
        component: () => import('../view/companyIntroduction.vue'),
    },
    {
        name: 'practice.aress',
        path: '/practiceAress',
        component: () => import('../view/practiceAress.vue'),
    },
    {
        name: 'legal.team',
        path: '/legalTeam',
        component: () => import('../view/legalTeams/legalTeam.vue')
    },
    {
        name: 'contact.us',
        path: '/contact',
        component: () => import('../view/contact.vue')
    },
    {
        name: 'asset.recovery',
        path: '/assetRecovery',
        component: () => import('../view/assetRecoverys/assetRecovery.vue'),
    },
    {
        name: 'Credit.Card',
        path: '/CreditCard',
        component: () => import('../view/assetRecoverys/CreditCard.vue'),
    },
    {
        name: 'Cryptocurrency',
        path: '/Cryptocurrency',
        component: () => import('../view/assetRecoverys/Cryptocurrency.vue'),
    },
    {
        name: 'Foreign.exchange',
        path: '/Foreignexchange',
        component: () => import('../view/assetRecoverys/Foreignexchange.vue'),
    },
    {
        name: 'Stockmarketfraud',
        path: '/Stockmarketfraud',
        component: () => import('../view/assetRecoverys/Stockmarketfraud.vue'),
    },
    {
        name: 'legalTeam',
        path: '/legalTeam',
        component: () => import('../view/legalTeams/legalTeam.vue'),
    },
    {
        name: 'assisStaff',
        path: '/assisStaff',
        component: () => import('../view/legalTeams/assisStaff.vue'),
    },
];

export default routes
